import * as React from "react"
import PropTypes from "prop-types"
import { Link } from 'gatsby'
import { Nav, Navbar } from 'react-bootstrap'

const Header = ({ siteTitle }) => (
  <Navbar className="px-5" bg="dark" expand="lg" variant="dark">
  <Navbar.Brand>
    <Link className="navbar-brand" to="/">Deglutino</Link>
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Item as="li">
        <Link to="/about" className="nav-link">About</Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Link to="/blog" className="nav-link">Blog</Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Link to="/contact-us/contact" className="nav-link">Contact</Link>
      </Nav.Item>
    </Nav>
  </Navbar.Collapse>
</Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
